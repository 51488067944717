<template>
  <div class="chart" v-show="size !== 'xs'">
    <Title class="chart-title" :title="$t('accountlist.graph')" />
    <div class="chart-main" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getPltCreatedAddresses } from "@/api/_common";
import Title from "@/components/MainTitle.vue";
import { mergeRecursive, generateColors } from "@/utils/graph";
import { useMq } from "vue3-mq";

const theme = {};

export default {
  components: {
    Title,
  },
  data() {
    return {
      size: null,
    };
  },
  watch: {
    $route: function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    const mq = useMq().current;
    this.size = mq;
    this.renderGraphs();
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize(e) {
      if (window.innerWidth < 576 && this.size !== "xs") {
        this.size = "xs";
      } else if (window.innerWidth > 576 && this.size === "xs") {
        this.size = "nxs";
      }
    },
    async renderGraphs() {
      const currentYear = new Date().getFullYear();
      const dataSet1 = await getPltCreatedAddresses({ Year: currentYear });
      const dataSet2 = await getPltCreatedAddresses({ Year: currentYear - 1 });
      const dataSet3 = await getPltCreatedAddresses({ Year: currentYear - 2 });
      const dataSet4 = await getPltCreatedAddresses({ Year: currentYear - 3 });

      for (let i = 1; i <= 4; i++) {
        const ds = eval(`dataSet${i}`);
        ds.MonthInfo.forEach((m) => {
          if (m.DayInfo !== null) {
            m.DayInfo.sort((a, b) => {
              if (a.Day > b.Day) {
                return 1;
              }
              if (a.Day < b.Day) {
                return -1;
              }
              return 0;
            }).reverse();
          }
        });
      }

      let currentTotalAmount = 0;
      const parsedData = [];
      for (let i = 4; i > 0; i--) {
        const ds = eval(`dataSet${i}`);
        ds.MonthInfo.forEach((month) => {
          if (month.DayInfo !== null) {
            month.DayInfo.reverse().forEach((day) => {
              currentTotalAmount += day.Amount;
              parsedData.push([
                `${ds.Year}-${month.Month}-${day.Day}`,
                currentTotalAmount,
              ]);
            });
          }
        });
      }

      const dateList = parsedData.map(function (element) {
        return element[0];
      });
      const valueList = parsedData.map(function (element) {
        return element[1];
      });
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const option = {
        title: {
          text: "PLT Cumulative Address Grownth",
          subtext:
            "The chart shows the total distinct numbers of adress on Palette Chain and the increse in the number of address daily",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            const previousElement = parsedData
              .map((element, index, array) => {
                if (element[0] === params[0].name) {
                  return array[index - 1];
                }
              })
              .filter((element) => element !== undefined)[0];
            return `${months[params[0].name.split("-")[1] - 1]} ${
              params[0].name.split("-")[2]
            }, ${
              params[0].name.split("-")[0]
            }:<br> [ <span style="color:#003a99;">Total Distinct Addresses</span>: <span style="font-weight: bold;">${
              params[0].value
            }</span> ]<br>
            Daily increase: <span style="font-weight: bold;">${
              previousElement !== undefined
                ? params[0].value - previousElement[1]
                : 0
            }</span>`;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateList,
          axisLabel: {
            interval: 28,
            formatter: function (value) {
              return `${value.split("-")[0].substr(2, 3)}'${
                months[value.split("-")[1] - 1]
              }`;
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: valueList,
            type: "line",
            symbol: "none",
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: generateColors(
                    getComputedStyle(
                      document.getElementsByClassName("chart")[0]
                    ).getPropertyValue("color"),
                    1
                  ),
                },
                {
                  offset: 1,
                  color: "rgb(204, 243, 255)",
                },
              ]),
            },
          },
        ],
      };

      const merged = mergeRecursive(theme, option);
      const chart = echarts.init(
        document.getElementsByClassName("chart-main")[0],
        null,
        { renderer: "svg" }
      );
      chart.setOption(merged);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/chart.scss";
div {
  max-width: 100%;
}
:deep(svg) {
  width: 100%;
  height: 100%;
}
</style>
